import React from 'react';
import styled from 'styled-components';
import loadable from "@loadable/component"
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import '../../styles/globals.css';
import { MetaComponent } from '../../components';
import {
  SassyText,
  Title,
  Section,
  SectionWrapper,
  Text,
  Button,
  Divider,
} from '../../components/core';
import { useMixpanel } from '../../context/MixpanelContext';
import useHindiLangFont from '../../hooks/useHindiLangFont';
import { LeadFormProvider } from '../../context/LeadFormContext';

import Bullet from '../../assets/images/bullet.svg';

const Footer = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Footer,
});
const LeadForm = loadable(() => import("../../components/LeadForm"));
const Navbar = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Navbar,
});

const HelpSection = loadable(() => import("../../components/HelpSection"));
const PolicyHeader = loadable(() => import("../../components/PolicyHeader"));

const PublicLiability = () => {
  const {hindiBlack, hindiBold, hindiRegular} = useHindiLangFont()
  const {t} = useTranslation(['pl', 'common'])
  const mixpanel = useMixpanel()
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

  const handleQuoteModalOpen = () => {
    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setIsQuoteModalOpen(false);
  };

  const handleGetQuoteClick = (mxEventName) => {
    if (mxEventName) mixpanel.track(mxEventName)
    handleQuoteModalOpen()
  }

  return (
    <>
      <LeadFormProvider>
        {isQuoteModalOpen ? <LeadForm onModalClose={handleQuoteModalClose} /> : null}
      </LeadFormProvider>
      <MetaComponent
        title="Public Liability Insurance - Verak"
        description="Secure your business against liabilities arising from third-party injuries or property damage. Get a quote."
        image="pb"
        url="liability/public_liability"
      />
      <Navbar active="products" />
      <PolicyHeader
        acronym="LIABILITY"
        title={
          <Trans i18nKey="Title" className={hindiBlack}>
            <SassyText>Public Liability </SassyText>Insurance
          </Trans>
        }
        content={t('Desc1')}
        subcontent={
          <Trans i18nKey="Desc2" className={hindiRegular}>
            Public Liability Insurance
            <strong className={hindiBold}>
              covers injuries or property damage suffered by third parties due
              to accidents occurring on the business premises.
            </strong>
            This policy is essential for restaurants, hotels, shopping centres,
            construction sites, and any other firm or establishment that
            directly interacts with the public.
          </Trans>
        }
      />
      <Section background="#FFF7F1">
        <SectionWrapper>
          <Title style={{ paddingBottom: '20px' }} className={hindiBlack}>
            <Trans i18nKey="WhatIsCovered.Title">What does Public Liability Insurance cover?</Trans>
          </Title>
          <Left>
            <Text mfontSize="16px" className={hindiRegular}>{t('WhatIsCovered.Subtitle')}</Text>
            <Container>
              <ul>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhatIsCovered.LegalLiability')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhatIsCovered.LegalCosts')}
                  </Text>
                </li>
              </ul>
            </Container>
          </Left>
        </SectionWrapper>
      </Section>
      <Section background="#ffffff">
        <SectionWrapper>
          <Title className={hindiBlack}>
            <Trans i18nKey="WhyOptForWC.Title">Why opt for this policy?</Trans>
          </Title>
          <Left>
            <Container>
              <ul>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyOptForWC.EssentialForSome.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    className={hindiRegular}
                  >
                    {t('WhyOptForWC.EssentialForSome.Desc')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyOptForWC.WideCover.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    className={hindiRegular}
                  >
                    {t('WhyOptForWC.WideCover.Desc')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyOptForWC.MandatoryForSome.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    className={hindiRegular}
                  >
                    {t('WhyOptForWC.MandatoryForSome.Desc')}
                  </Text>
                </li>
              </ul>
            </Container>
            <Text
              fontSize="16px"
              mfontSize="16px"
              style={{ paddingBottom: '50px' }}
            >
              <Trans i18nKey="WhyOptForWC.LearnAboutWC" className={hindiRegular}>
                Learn about <strong className={hindiBold}>Public Liability Insurance</strong> in detail
                <a href="https://blog.verak.in/product-explainers-public-liability-insurance-amp/">
                  <SassyText> HERE</SassyText>
                </a>
              </Trans>
            </Text>
            <Button wide label={t('CTALabels.GetAQuote')} onClick={() => handleGetQuoteClick("Clicked-Down - 'Get a Quote' - PL LP")} className={hindiBold} />
          </Left>
        </SectionWrapper>
      </Section>
      <HelpSection />
      <Divider />
      <Footer />
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["pl", "index", "common", "navbar", "footer", "getQuoteModal", "helpSection"]
        }, 
        language: {eq: $language 
      }
    }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Left = styled.div`
  text-align: left;

  @media screen and (max-width: 768px) {
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin: 20px 0px;

  li {
    color: #999999;
    margin-left: 24px;
    margin-bottom: 25px;
    list-style: none;
  }

  p {
    display: flex;
    align-items: center;
  }

  .bullet {
    min-width: 12px;
    margin-left: -20px;
    margin-right: 35px;
  }

  .sublist li {
    margin-bottom: 0;
  }
`;

export default PublicLiability;
